import { SxStyleProp } from "theme-ui";

const postListStyles: SxStyleProp = { m: 0, p: 0 };

const postListItemStyles = {
  listStyle: "none",
  margin: 0,
};

export { postListStyles, postListItemStyles };
